import request from '@/utils/request'

// api地址
const api = {
  page: '/sys/userReal/page',
  realPass: '/rnt/client/realPass',
  realRefuse: '/rnt/client/realRefuse'
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 实名认证通过
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function realPass(data) {
  return new Promise((resolve, reject) => {
    request.post(api.realPass, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 实名认证拒绝
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function realRefuse(data) {
  return new Promise((resolve, reject) => {
    request.post(api.realRefuse, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
