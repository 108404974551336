<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="真实姓名" name="realName">
              <a-input
                placeholder="请输入真实姓名"
                v-model:value="form.realName"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="phone">
              <a-input
                placeholder="请输入手机号"
                v-model:value="form.phone"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="身份证号" name="idcard">
              <a-input
                placeholder="请输入身份证号"
                v-model:value="form.idcard"
              ></a-input> </a-form-item
          ></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <!-- <span
            v-if="!expand"
            @click="expandForm"
            style="color: #1890ff; cursor: pointer"
          >
            展开<i class="iconfont icon-xiangxiajiantou"></i>
          </span>
          <span
            v-else
            @click="expandForm"
            style="color: #1890ff; cursor: pointer"
          >
            收起<i class="iconfont icon-xiangshang_jiantou"></i>
          </span> -->
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.phone"
          :columns="columns"
          :datasource="datasource"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #toolbar>
          </template>
          <template #state="{ record }">
            {{
              record.state === '1'
                ? '通过'
                : record.state === '2'
                ? '不通过'
                : record.state === '3'
                ? '撤销'
                : record.state === '0'
                ? '审核中'
                : ''
            }}
          </template>
          <template #action="{ record }">
            <a-space>
              <a @click="openDetail(record)">详情</a>
              <!-- <a @click="openEdit(record)">修改</a> -->
              <!-- <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项目吗？">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm> -->
            </a-space>
          </template>
        </ud-pro-table>
      </div>
      <real-name-detail
        v-model:visible="showDetail"
        :data="current || {}"
        @done="reload"
      />
    </a-card>

    <!-- <real-name-edit v-model:visible="showEdit" :data="current" @done="reload" /> -->
  </div>
</template>

<script>
import realNameDetail from './realNameDetail.vue'
import * as realNameApi from '@/api/sys/realNameRecord.js'
// import operationEdit from './operation-edit.vue'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    realNameDetail
    // DownOutlined,
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  data() {
    return {
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      //   expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '手机号',
          // width: 170,
          // align: 'center',
          dataIndex: 'phone'
        },
        {
          title: '真实姓名',
          // width: 85,
          // align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '身份证号',
          // width: 175,
          // align: 'center',
          dataIndex: 'idcard'
        },
        {
          title: '状态',
          // width: 175,
          // align: 'center',
          dataIndex: 'state',
          slots: {
            customRender: 'state'
          }
        },
        {
          title: '结果',
          // width: 175,
          // align: 'center',
          dataIndex: 'result'
        },
        {
          title: '操作',
          width: 200,
          dataIndex: 'action',
          align: 'center',
          slots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  methods: {
    // 获取表格数据
    datasource(option, callback) {
      realNameApi
        .page({
          ...this.form,
          page: option.page,
          limit: option.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      // this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.form = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // // 新增计费模板确定按钮回调事件
    // handleBillingOk() {
    //   this.$refs.billingForm.validate().then((res) => {
    //     console.log(res)
    //     this.$message.success('添加计费模板成功！')
    //     this.visible = false
    //   })
    // },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
