<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="500"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <template #footer>
      <a-space>
        <a-button @click="handleCancle"> 取消 </a-button>
        <a-button type="primary" @click="pass"> 通过 </a-button>
        <a-button type="danger" @click="reject"> 驳回 </a-button>
      </a-space>
    </template>
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 8 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 厂家 -->
          <a-form-item label="手机号:">
            <div class="ud-text-secondary">
              {{ data.phone }}
            </div>
          </a-form-item>
          <!-- 机柜尺寸 -->
          <a-form-item label="身份证号码:">
            <div class="ud-text-secondary">{{ data.idcard }}</div>
          </a-form-item>
          <!-- 型号 -->
          <a-form-item label="真实姓名:">
            <div class="ud-text-secondary">{{ data.realName }}</div>
          </a-form-item>
          <!-- 管理员身份证(正面) -->
          <a-form-item label="身份证(正面):">
            <div class="ud-text-secondary">
              <img
                class="photo"
                :src="data.idcardPic"
                :width="200"
                :height="200"
              />
            </div>
          </a-form-item>
          <!-- 管理员身份证(反面) -->
          <a-form-item label="身份证(反面):">
            <div class="ud-text-secondary">
              <img
                class="photo"
                :src="data.idcardPic2"
                :width="200"
                :height="200"
              />
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="showPass"
    :width="500"
    title="同意"
    centered
    @ok="handlePass"
  >
    <a-form-item label="备注">
      <a-textarea placeholder="请输入同意理由" v-model:value="passReason">
      </a-textarea>
    </a-form-item>
  </a-modal>
  <a-modal
    v-model:visible="showReject"
    :width="500"
    title="拒绝"
    centered
    @ok="handleReject"
  >
    <a-form :rules="rules" ref="form" :model="rejectForm">
      <a-form-item label="备注" name="rejectReason">
        <a-textarea
          placeholder="请输入拒绝理由"
          v-model:value="rejectForm.rejectReason"
        >
        </a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as realNameApi from '@/api/sys/realNameRecord.js'
export default {
  name: 'brandDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      showPass: false,
      showReject: false,
      passReason: '',
      rejectForm: {},
      rules: {
        rejectReason: [
          { required: true, message: '请输入拒绝理由', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    data() {
      if (this.data.state === '1' && this.data.result) {
        this.passReason = this.data.result
      }
      if (this.data.state === '2' && this.data.result) {
        this.rejectForm.rejectReason = this.data.result
      }
    }
  },
  methods: {
    // 处理通过请求
    handlePass() {
      realNameApi
        .realPass({
          result: this.passReason,
          userId: this.data.userId
        })
        .then((res) => {
          if (res.code === 0) {
            this.showPass = false
            this.passReason = ''
            this.rejectForm = {}
            this.updateVisible(false)
            this.$emit('done')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 处理拒绝请求
    handleReject() {
      this.$refs.form
        .validate()
        .then(() => {
          realNameApi
            .realRefuse({
              result: this.rejectForm.rejectReason,
              userId: this.data.userId
            })
            .then((res) => {
              if (res.code === 0) {
                this.showReject = false
                this.rejectForm = {}
                this.passReason = ''
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.$message.error(e.message)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 通过实名认证
    pass() {
      this.showPass = true
    },
    // 驳回实名认证
    reject() {
      this.showReject = true
    },
    handleCancle() {
      this.$emit('update:visible', false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped lang='less'>
.photo {
  margin-bottom: 10px;
}
</style>
